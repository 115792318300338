<template>
  <v-dialog v-model="show" max-width="796px" persistent>
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card class="pb-8" v-else>
      <div v-if="paymentStatus">
        <div
          style="background: linear-gradient(180deg, #82c2e6 0%, #f2f8fc 100%)"
        >
          <v-btn
            class="mt-3 mr-4"
            icon
            style="float: right"
            @click="
              (show = false),
                changeStatus(donationDetails.pledge, donationDetails.id)
            "
          >
            <v-icon color="#1D1D1D" size="32">mdi-close</v-icon>
          </v-btn>
          <div class="d-flex px-16 py-10 pt-16">
            <div>
              <v-img
                v-if="donationDetails.logo_url"
                width="150px"
                class="rounded-lg"
                :src="donationDetails.logo_url"
              ></v-img>
              <v-img
                v-else
                max-width="150px"
                src="@/assets/publicDonationPage/student.png"
              ></v-img>
            </div>
            <div class="ml-14 upperBody-text">
              Thank you
              <span style="color: #cc0000">{{
                donationDetails.donor_name
              }}</span>
              for your online donation of ${{
                donationDetails.donated_amount
              }}
              for {{ donationDetails.donation_for_name }} participating in the
              {{
                donationDetails.school_name
                  ? `${donationDetails.school_name}'s `
                  : ""
              }}
              WizFit Challenge
            </div>
          </div>
        </div>
        <v-divider style="border: 1px dashed #757575"></v-divider>
        <div class="px-16">
          <span
            class="textField-label"
            style="display: inline-flex; align-items: center"
          >
            <v-checkbox
              color="#38A737"
              v-model="pledge"
              @change="changeStatus(donationDetails.pledge, donationDetails.id)"
            ></v-checkbox>
            <label style="font-size: 20px; margin-left: 10px">
              I pledge to donate 20% extra when
              {{ donationDetails.donation_for_name }} completes the WizFit video
              challenge
            </label>
          </span>
        </div>
        <div style="" class="px-16 alignCenter-text">
          <div class="mt-6 lowerBody-secondryTextSecond px-5">
            Please also consider sharing this fundraiser using the below social
            media icons. This generous gesture is proven to give extra life to
            our schools fundraiser.
          </div>

          <div class="alignCenter-text">
            <v-sheet
              class="pa-1 mt-8 d-flex py-3"
              color="white"
              elevation="4"
              width="90%"
              style="
                box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                justify-content: space-evenly;
              "
            >
              <div>
                <a
                  :href="`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  target="_blank"
                  title="Share on Facebook"
                >
                  <v-img
                    style="cursor: pointer"
                    width="35px"
                    src="@/assets/ShareLogo/facebookShareLogo.svg"
                  ></v-img>
                </a>
              </div>
              <div v-if="false">
                <v-img
                  style="cursor: pointer"
                  @click="shareClickHandler('instagram')"
                  width="35px"
                  src="@/assets/ShareLogo/instagramShareLogo.svg"
                ></v-img>
              </div>
              <div>
                <a
                  :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&t=${title}`"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  target="_blank"
                  title="Share on Linkedin"
                >
                  <v-img
                    style="cursor: pointer"
                    width="35px"
                    src="@/assets/ShareLogo/linkedinShareLogo.svg"
                  ></v-img>
                </a>
              </div>
              <div v-if="false">
                <v-img
                  style="cursor: pointer"
                  @click="shareClickHandler('snapchat')"
                  width="35px"
                  src="@/assets/ShareLogo/snapchatShareLogo.svg"
                ></v-img>
              </div>
              <div v-if="false">
                <v-img
                  style="cursor: pointer"
                  @click="shareClickHandler('tikTok')"
                  width="35px"
                  src="@/assets/ShareLogo/tikTokShareLogo.svg"
                ></v-img>
              </div>
              <div>
                <a
                  :href="`https://twitter.com/share?url=${url}&text=${title}`"
                  onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  target="_blank"
                  title="Share on Twitter"
                >
                  <v-img
                    style="cursor: pointer"
                    width="35px"
                    src="@/assets/ShareLogo/twitterShareLogo.svg"
                  ></v-img>
                </a>
              </div>
              <div>
                <a
                  :href="`whatsapp://send?text=${title}`"
                  data-action="share/whatsapp/share"
                  onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                  target="_blank"
                  title="Share on whatsapp"
                >
                  <v-img
                    style="cursor: pointer"
                    width="35px"
                    src="@/assets/ShareLogo/whatsappShareLogo.svg"
                  ></v-img>
                </a>
              </div>
              <v-btn
                v-clipboard:copy="url"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                class="copyUrlBtn"
                color="#2C1963"
                outlined
              >
                <v-icon size="25" left>mdi-link-variant</v-icon>
                <span>Copy URL Link</span>
              </v-btn>
            </v-sheet>
            <div class="mt-5">
              <v-btn @click="show = false" text class="lowerBody-doLaterBtn">
                <span>CLOSE</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          style="background: linear-gradient(180deg, #82c2e6 0%, #f2f8fc 100%)"
        >
          <v-btn
            class="mt-3 mr-4"
            icon
            style="float: right"
            @click="show = false"
          >
            <v-icon color="#1D1D1D" size="32">mdi-close</v-icon>
          </v-btn>
          <div class="px-16 py-8 pt-16" style="text-align: center">
            <h2>Payment Unsuccessful!</h2>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_PUBLIC_GET_DONATION_DETAILS,
  API_DONATION_STATUS_PLEDGE,
} from "@/constants/APIUrls";

export default {
  name: "ThankYouModal",
  components: {},
  data() {
    return {
      loading: true,
      url: window.location.href,
      title: "WizFit Challenge",
      paymentStatus: false,
      donationDetails: {},
      pledge: true,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getThankYouModalShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        if (!value) {
          let query = Object.assign({}, this.$route.query);
          delete query.donation_id;
          delete query.payment_status;
          this.$router.replace({ query });
        }
        return this.toggleModal({ show: value });
      },
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleThankYouModal",
      showToast: "snackBar/showToast",
    }),
    getDetails() {
      const _this = this;
      const successHandler = (res) => {
        console.log("Success: ", res.data);
        _this.donationDetails = res.data.donation_detail;
        _this.loading = false;
        if(_this.donationDetails){
        window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
	'event':'donate_now',
	'donation_value':_this.donationDetails.donated_amount
});
}
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
        this.loading = false;
      };
      let formData = {};
      formData["donation_id"] = this.$route.query.donation_id;
      Axios.request_GET(
        API_PUBLIC_GET_DONATION_DETAILS,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    shareClickHandler(type) {
      console.log("Type: ", type);
    },
    onCopy: function () {
      this.showToast({ message: "URL Copied", color: "s" });
    },
    onError: function () {
      this.showToast({ message: "URL NOT Copied", color: "e" });
    },
    changeStatus(status, id) {
      console.log(status, id);
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = id;
      formData["status"] = this.pledge;
      Axios.request_PATCH(
        API_DONATION_STATUS_PLEDGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.loading = true;
    this.paymentStatus =
      this.$route.query.payment_status === "success" ? true : false;
    this.getDetails();
  },
};
</script>

<style scoped>
.copyUrlBtn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #2c1963;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.alignCenter-text {
  text-align: -webkit-center;
  text-align: -moz-center;
}
.upperBody-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 145%;
  color: #2d2d2d;
  align-self: center;
}
.lowerBody-secondryTextFirst {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  color: #2d2d2d;
}
.lowerBody-secondryTextSecond {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 145%;
  text-align: center;
  color: #2d2d2d;
}
.lowerBody-doLaterBtn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 145%;
  text-decoration-line: underline;
  color: #cc0000;
  letter-spacing: 0px;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    color: black;
    font-size: 20px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
